<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                outlined
                dense
                hide-details
                clearable
                name="name"
                label="Search"
                v-model="query.search"
                placeholder="Enter Search Keyword"
              />
            </v-col>
            <v-spacer />
            <v-col cols="6" sm="2">
              <v-btn
                block
                outlined
                :loading="loading.export"
                :disabled="desserts.length === 0"
                color="primary"
                @click="exportExcel()"
                >Export</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table :headers="headers" :items="desserts" hide-default-footer>
          <template v-slot:footer>
            <v-pagination
              v-if="totalPage"
              class="text-right text-body-2 py-2 "
              circle
              :total-visible="5"
              color="primary"
              v-model="query.page"
              :length="totalPage"
            ></v-pagination>
          </template>
        </v-data-table>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { PromoterEventService } from '@services';
import pdf from 'vue-pdf';

export default {
  data() {
    return {
      query: {
        search: '',
        page: 1,
      },
      valid: false,
      totalPage: '',
      headers: [],
      loading: {
        export: false,
      },
      currentPage: 0,
      pageCount: 0,
      event: '',
      desserts: [],
    };
  },

  created() {
    this.event = this.$route.params.id;
    this.getSurveyReport();
  },
  methods: {
    async getSurveyReport() {
      const response = await PromoterEventService.getSurveyReport(this.query, this.event);
      console.log(response);
      if (response) {
        this.desserts = response.data;
        this.headers = [];
        this.headers.push(
          { text: 'Name', value: 'name', sortable: false, width: '250px' },
          { text: 'Email', value: 'email', sortable: false, width: '250px' }
        );
        for (let index = 0; index < response.questions.length; index++) {
          const element = response.questions[index];
          this.headers.push({ text: element.question, value: element._id, sortable: false, width: '250px' });
        }
      }
    },
    async exportExcel() {
      this.loading.export = true;
      const fileName = `Survey-Report-${moment().format('DD/MM/YYYY')}.xlsx`;
      let pageName = 'Callover List';
      const exportdata = await PromoterEventService.exportSurveyReport(this.query, this.event);;
      if (exportdata) {
        const url = URL.createObjectURL(new Blob([exportdata]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        this.loading.export = false;
      }
      this.loading.export = false;
    }
  },
  watch: {
    'query.search': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.search && this.query.search.length > 2) {
          this.query.page = 1;
          this.getSurveyReport();
        }
        if (!this.query.search) {
          this.query.page = 1;
          this.getSurveyReport();
        }
      }, 600);
    },
  },
  components: {
    pdf,
  },
};
</script>
